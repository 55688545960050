<template>
  <div class="footer-copyRight">
    <span>
      <i class="bi bi-c-circle copyright"></i>
      <span class="copyRightText"> 2025 noyush_byshira , כל הזכויות שמורות</span>
    </span>
    <span>
      <a href="https://www.instagram.com/david_dabach/"><i class="bi bi-instagram icons-footer insta"></i></a>
      <a href="https://www.facebook.com/david.benzohar"><i class="bi bi-facebook icons-footer"></i></a>
      <a href="https://wa.link/t0lyzc"><i class="bi bi-whatsapp icons-footer"></i></a>
      <a href="https://www.instagram.com/david_dabach/"><i class="bi bi-twitter icons-footer"></i></a>
      <a href="https://www.instagram.com/david_dabach/"><i class="bi bi-snapchat icons-footer"></i></a>
    </span>
    
  </div>

</template>

<script>




export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.insta:hover {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
          /* Also define standard property for compatibility */
          background-clip: text;
  -webkit-text-fill-color: transparent;
}


.bi-facebook:hover{
  color: #4267B2
;
}

.bi-whatsapp:hover{
  color: green;
}

.bi-twitter:hover{
  color:rgb(0, 191, 255);
}
.bi-snapchat:hover{
  color: yellow;
}


.footer-copyRight{
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  direction: rtl;
  align-items: center;
  background-color: rgb(42, 42, 42);
  padding: 10px 0px;
  width: 100%;
  
}

.copyright , .copyRightText{
  position: relative;
  color: white;
  right: 1%;
  top: 21%;
}

.copyright{
  right: 0.5%;
}

.icons-footer{
  position: relative;
  font-size: 22px;
  padding-left: 1vw;
  top: 8%;
  transition: 0.2s ease-in-out;
  color: whitesmoke;
}


@media (max-width: 725px) {

  .copyRightText,.copyright{
    font-size: 13px;
  }

  .icons-footer{
    font-size: 20px;
    padding-left: 1.6vw;
  }

  
}



</style>
