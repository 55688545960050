<template>
<NavBar ref="NavbarCart"/>
<poster Title="מגוון עוגות טעימות"/>

<div class="ExtraDiv" v-if="this.OpenExtrasDiv">
  <div>
    <!-- <h1>{{ this.indexExtras.title }}</h1> -->
    <p>
      <label>מעוניין להוסיף תוספות? :
        <select v-model="this.extrasDial">
          <option value="Mnm">Mnm</option>
          <option value="שברי אוראו">שברי אוראו</option>
          <option value="עוד קומה">עוד קומה</option>
          <option value="עוד קרמל">עוד קרמל</option>
        </select>
      </label>
    </p>
    <button class="btn closeBtn"  @click="CloseExtras()"><i class="bi bi-x-lg"></i></button>
    <div class="buttonDialog">
      <button class="btn btn-primary" @click="addToCart()">הוספה לסל <i class="bi bi-cart4"></i></button>
    </div>
  </div>
</div>

<section class="container MenuContainer">
  <!-- <div class="NavDiv"> -->
      <!-- @Tag-Filter="this.FilterTag" -->
    <!-- <NavMenu :Options="this.Options" :EditMode="this.UserEditMode" @Update-DB="this.UpdateDb" /> -->
  <!-- </div> -->
  <div class="SortDiv">
    <!-- <h1>סינון</h1>
    <hr> -->
    <div class="AllOptions">
      <div class="OptionsSort">
        <label for="">טווח מחירים</label>
        <div class="range-slider">
          <div class="range-fill" :style="{ right: minPos + '%', width: (maxPos - minPos) + '%' }"></div>

          <!-- שתי נקודות אחיזה -->
          <input type="range" v-model.number="minPrice" :min="min" :max="max" step="5" @input="validateRange('min')">
          <input type="range" v-model.number="maxPrice" :min="min" :max="max" step="5" @input="validateRange('max')">
        </div>

        <!-- הצגת הערכים -->
        <div class="values">
          <input type="text" v-model.number="minPrice" @input="validateRange('min')">
          <input type="text" v-model.number="maxPrice" @input="validateRange('max')">
          
        </div>
      </div>
      <div class="OptionsSort">
        <label for="">לפי קטגוריה</label>
        <select v-model="categoriesData">
          <option value="הכל">הכל</option>
          <option v-for="categorie in categories" :value="categorie" :key="categorie">{{ categorie }}</option>

          </select>
      </div>
      <div class="OptionsSort">
          <label for="">רגישויות ואלרגנים</label>
          <select v-model="allergensData">
            <option value="ללא">ללא</option>
            <option v-for="allergen in allergens" :value="allergen" :key="allergen">{{ allergen }}</option>
          </select>
      </div>
    </div>
    
  </div>
  <div class="MenuDiv">
    <h1 v-if="CakeFilter.length == 0 && this.loadingCheck == false" style="margin: auto;">לא נמצאו פריטים</h1>
    
    <!-- <CakeContainer v-else-if="this.loadingCheck == false" v-for="(cake, index) in CakeFilter" :key="cake" 
        :CakeImgMenu="cake.img" :title="cake.title"
        :price="cake.price" :tags="cake.tags" :idCake="cake.idCake"
        :paragrafh="cake.paragrafh" :id="cake.id" @localAddProduct="OpenExtras($event)"/>  -->

        <CakeContainerNew v-else-if="this.loadingCheck == false" v-for="(cake, index) in CakeFilter" :key="index" 
        :CakeImgMenu="cake.img" :title="cake.title"
        :price="cake.price" :tags="cake.tags" :idCake="cake.idCake"
        :paragrafh="cake.paragrafh" :id="cake.id" :CakeData="cake"  @localAddProduct="OpenExtras($event)"/> 

        <CakeContainerSkeleton v-else />
        
    

        
    
      <!-- <CakeContainerSkeleton/> -->
  </div>
       
  
   


</section>

  <Footer/>
</template>



<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import CakeContainer from '@/components/CakeContainer.vue'
import CakeContainerNew from '@/components/CakeContainerNew.vue'
import CakeContainerSkeleton from '@/components/CakeContainerSkeleton.vue'
import poster from '@/components/posterPic.vue'
import NavMenu from '@/components/navMenu.vue'
import axios from 'axios'


export default {
  name: 'Cart',
  components: {
    NavBar,
    Footer,
    poster,
    CakeContainer,
    CakeContainerNew,
    CakeContainerSkeleton,
    NavMenu
  },
  data(){
    return{
    MenuText:'תפריט',
    UserEditMode:false,
    OpenExtrasDiv:false,
    cakes:[],
    cakeBackup:[],
    products:[],
    cakeIdSet:[],
    CartData:[],
    indexExtras:[],
    min:0,
    max:2000,
    minPrice:0,
    maxPrice:2000,
    buffer: 50,
    categoriesData:'הכל',
    allergensData:'ללא',
    categories:['שוקולד','וניל','יום הולדת','גיוס','חתונה','שבת'],
    allergens:['ללא בוטנים','ללא אגוזים','ללא חלב','ללא קוקוס'],
    loadingCheck:null,
    idForExtra:null,
    extrasDial:'',
    
    }

  },
  methods:{
    validateRange(changed) {
      // בדיקת ערכים ריקים והגדרת ברירת מחדל
      this.minPrice = this.minPrice?.toString().length === 0 ? this.min : this.minPrice;
      this.maxPrice = this.maxPrice?.toString().length === 0 ? this.max : this.maxPrice;

      // הגבלת ערכים לטווח המינימום והמקסימום
      this.minPrice = Math.max(this.minPrice, this.min);
      this.maxPrice = Math.min(this.maxPrice, this.max);

      // מניעת חפיפה עם מרווח מינימלי
      if (changed === 'min' && this.minPrice > this.maxPrice - this.buffer) {
        this.minPrice = this.maxPrice - this.buffer;
      } 
      if (changed === 'max' && this.maxPrice < this.minPrice + this.buffer) {
        this.maxPrice = this.minPrice + this.buffer;
      }
    },
    async getdata(){

      // this.cakes = [
      // {id:1 , CakeImgMenu:'',title:"ניסיון",price:300,tags:['שבת','שוקולד'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא חלב']},
      // {id:2 , CakeImgMenu:'',title:"1 ניסיון",price:200,tags:['שבת','וניל'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא קוקוס']},
      // {id:3 , CakeImgMenu:'',title:"2 ניסיון",price:150,tags:['יום הולדת'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:[]},
      // {id:4 , CakeImgMenu:'',title:"3 ניסיון",price:220,tags:['וניל'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא חלב']},
      // {id:5 , CakeImgMenu:'',title:"4 ניסיון",price:225,tags:['שוקולד'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:[]},
      // {id:6 , CakeImgMenu:'',title:"5 ניסיון",price:350,tags:['יום הולדת'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא בוטנים']},
      // {id:7 , CakeImgMenu:'',title:"6 ניסיון",price:400,tags:['וניל'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא אגוזים']},
      // {id:8 , CakeImgMenu:'',title:"7 ניסיון",price:300,tags:['שוקולד'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא חלב']},
      // {id:9 , CakeImgMenu:'',title:"8 ניסיון",price:200,tags:['dsa','lly'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:[]},
      // {id:10 , CakeImgMenu:'',title:"9 ניסיון",price:150,tags:['יום הולדת'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא בוטנים']},
      // {id:11 , CakeImgMenu:'',title:"10 ניסיון",price:220,tags:['וניל','יום הולדת'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:[]},
      // {id:12 , CakeImgMenu:'',title:"11 ניסיון",price:225,tags:['שוקולד'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא אגוזים']},
      // {id:13 , CakeImgMenu:'',title:"12 ניסיון",price:350,tags:['יום הולדת'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:[]},
      // {id:14 , CakeImgMenu:'',title:"13 ניסיון",price:400,tags:['שבת'],paragrafh:'daasdsa fds fdsg sdgdsfg dsfgds fgds fgds dsfg dsfgds ',allergens:['ללא חלב']},
      // ]


      // setTimeout(() =>{
      //   this.loadingCheck = false

      // },2000)

      await axios.get('/.netlify/functions/GetDataCollectionCakes').then(response => {
          console.log(response.data || []);
          var arr = []
          Object.keys(response.data).forEach(key => arr.push(response.data[key]))
          console.log(Array.isArray(arr))
          this.cakes = arr
          this.loadingCheck = false
      }).catch(error => {
          console.log(error);
      }); 
    },
    OpenExtras(item){
      this.indexExtras.push(item)
      console.log(this.indexExtras)
      this.OpenExtrasDiv = true
    },
    CloseExtras(){
      this.indexExtras = []
      this.extrasDial = ''
      this.OpenExtrasDiv = false

    },
    addToCart(){
      // delete item.item;
      console.log(this.indexExtras)
      this.indexExtras[0].extras.push(this.extrasDial)

      const SessionData = JSON.parse(sessionStorage.getItem('CartItems'));
      const newItem = this.indexExtras[0]; // Assume 'item' is defined somewhere
      // console.log(newItem)

      let test = SessionData || [];

      const sameIndexItems = test.filter(existingItem => existingItem.id == newItem.id);
      console.log(sameIndexItems)
      console.log(newItem)

      let itemExists = false;
      if(test){
        for (const existingItem of sameIndexItems) {
          if (newItem.extras.length === existingItem.extras.length && newItem.extras.every(item => existingItem.extras.includes(item))) {
            // If the extras are identical, update Item
            existingItem.inCart++;
            itemExists = true;
            console.log("have")
            

          } else {
            // If extras are not identical, add new item
            itemExists = false;
            console.log(newItem.extras)
            console.log(existingItem.extras)
            // Exit the loop as we found a match
          }
        }

        if (!itemExists) {
          // If no matching extras were found, add the new item
          test.push(newItem);
        }
      }
      
      sessionStorage.setItem('CartItems', JSON.stringify(test));
      // this.SessionData()
      this.$refs.NavbarCart.SetData()
      this.CloseExtras()
      // this.scrollToElement('cart')
      
    },
  },
  created(){
    this.loadingCheck = true
    this.getdata()
  },
  mounted(){
    window.scrollTo(0,0)
  },
  computed: {
    InputSFilter() {
      // const query = this.InputSearch.toLowerCase();

      return this.cakes.filter(item => {
        const matchesTag = this.selectedTags === 'הכל' || item.tagsCake.includes(this.selectedTags);
        // const matchesQuery = 
        //   item.titleCake.toLowerCase().includes(query) || 
        //   item.priceCake.toLowerCase().includes(query) ||
        //   item.tagsCake.includes(query);
        // return matchesTag && matchesQuery;
        return matchesTag;
      });
      
    },
    CakeFilter(){
      return this.cakes.filter((cake) => 
      (this.allergensData === "ללא" || cake.allergens.includes(this.allergensData)) &&
      (this.categoriesData === "הכל" || cake.tags.includes(this.categoriesData)) &&
      (cake.price >= this.minPrice && cake.price <= this.maxPrice))
    },
    minPos() {
      return (this.minPrice / this.max) * 100;
    },
    maxPos() {
      return (this.maxPrice / this.max) * 100;
    }

  }

  
}
</script>

<style scoped>

.empty{
  height: 1000px;
}

/* ---------- slider ----------- */

.ExtraDiv{
  position: fixed;
  direction: rtl;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 70%;
  height: 20svh;
  z-index: 100000000000;
  padding: 10px;
  border-radius: 15px;
  background: #f7e7d7;
  border: 1px solid black;
}

.range-slider {
  position: relative;
  display: block;
  height: 6px;
  width: 90%;
  background: #ddd;
  border-radius: 3px;
}

.range-fill {
  position: absolute;
  height: 100%;
  background: #007bff;
  border-radius: 3px;
}

input[type="range"] {
  border: 1px solid none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  width: 100%;
  right: 0;
  height: 6px;
  background: transparent;
  pointer-events: none; /* כדי לא לחסום את הסליידר שמתחת */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #007bff;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all; /* מאפשר גרירה של הנקודה */
}

.values {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 300px;
}

.values input{
  width: 50px;
  text-align: center;
}

.SortDiv{
  display: flex;
  flex-direction: column;
  background: #f7e7d7;
  border-radius: 15px;
  width: 100%;
  /* height: 200px; */
  color: #4b2d3d;
  font-weight: bold;
  text-align: center !important;
  padding: 10px;
  /* gap: 20px; */
}


.AllOptions{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.OptionsSort{
  border: 1px solid none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid none;
  gap: 20px;
  width: 33.33%;
  padding: 20px;
}

.OptionsSort input{
  border-radius: 15px;
}

.OptionsSort select{
  border-radius: 25px;
  height: 40px;
  width: 100%;
  padding: 10px;
}


.ExtraDiv label{
  position: relative;
  top: 20px;
  font-size: 20px;
}

.ExtraDiv .buttonDialog {
  border: 1px solid none;
  width: 30%;
  position: absolute;
  display: flex;
  right: 0;
  left: 0;
  bottom: 5%;
  margin-right: auto;
  margin-left: auto;
  justify-content: space-evenly;
}

.ExtraDiv button.closeBtn{
  position: absolute;
  top: 2%;
  left: 1%;
  outline: none !important;
}
.ExtraDiv button.closeBtn i{
  font-size: 28px;
}

.MenuContainer {
  position: relative;
  direction: rtl;
  margin-top: 80px;
  padding: 0px 0px 50px 0;
  border: 1px solid none;
  display: flex;
  flex-direction: column;
  gap: 20px 0px;
  width: 100%;  
}

.MenuDiv{
  border: 1px solid none;
  position: relative;
  width: 100%;
  display: grid;
  direction: rtl;
  grid-template-columns: repeat(5,minmax(0,1fr));
  gap: 20px;
  padding: 10px 0px 20px 0px;
}

.MenuDiv h1{
  position: absolute;
  right: 50%;
  transform: translate(50%);
  
}

/* .NavDiv{
  border: 1px solid none;
  position: sticky;
  top: 180px;
  width: 20%;
  height: fit-content;
} */

@media (max-width: 1200px){
  .MenuDiv{
    grid-template-columns: repeat(4,minmax(0,1fr));
  }


  .AllOptions{
    display: flex;
    flex-direction: column;
    
  }

  .OptionsSort{
    width: 100%;
  }
} 

@media (max-width: 984px) {

  dialog{
    width: 80% !important;
    z-index: 8787827827;
  }
  dialog label{
    font-size: 15px;
  }

  dialog select{
    position: absolute;
    top: 40px;
    right: 0;
  }
}

@media (max-width: 990px) {

  .MenuContainer{
    flex-direction: column;
  }

  .MenuDiv{
    grid-template-columns: repeat(3,minmax(0,1fr));
    width: 100%;
    gap:10px;
  }

  .NavDiv{
    border: 1px solid none;
    width: 100%;
  }
}

@media (max-width: 580px) {
  .MenuDiv{
    grid-template-columns: repeat(2,minmax(0,1fr));
    width: 90%;
    margin: auto;
    gap:15px;
  }
}


</style>




