<template>
<NavBar/>
<br>
<br>
<br>
<br>
<br>
<br>
<br>

<div>
  <label>תמונה:</label>
  <img :src="this.CakeImg" alt="" v-if="this.CakeImg">
  <button class="btn ChangeButton" @click="this.openWigit"><i class="bi bi-upload"></i></button>
  <br>
  <label>שם:</label>
  <input type="text" v-model="titleCake" required>
  <br>
  <label>מחיר:</label>
  <input type="number" v-model.number="priceCake" required>
  <br>
  <label>תגים:</label>
  <select v-model="tagsCake">
    <option v-for="categorie in categories" :key="categorie" :value="categorie">{{ categorie }}</option>
  </select>
  <br>
  <label>אלרגנים:</label>
  <select v-model="allergensCake">
    <option v-for="allergen in allergens" :key="allergen" :value="allergen">{{ allergen }}</option>
  </select>
  <br>
  <label>תיאור:</label>
  <br>
  <textarea type="text" v-model="paragrafh"></textarea>
  <br>
  <button @click="postData()">Submit</button>
</div>



  
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'



export default {
  name: 'Home',
  components: {
    NavBar,
    Footer,
},
  data(){
      return{
        categories:['אין','שוקולד','וניל','יום הולדת','גיוס','חתונה','שבת'],
        allergens:['אין','ללא בוטנים','ללא אגוזים','ללא חלב','ללא קוקוס'],
        data: [],
        CakeImg:'',
        titleCake:'',
        priceCake:null,
        tagsCake:'',
        allergensCake:'',
        paragrafh:'',
      }
  },
  methods: {
    getda(){
      axios.get('/.netlify/functions/GetDataCollectionCakes').then(response => {
          console.log(response.data);
          this.data = response.data
      }).catch(error => {
          console.log(error);
      });
    },
    openWigit(){

      const widget = window.cloudinary.createUploadWidget(
        {cloud_name:"ds13xlamk", upload_preset: "Noyush"},
        (error,result)=>{
          if(!error && result && result.event == "success"){
            console.log("Done uploading ....",result.info.url)
            this.CakeImg = result.info.url      
          }
        }
      )
      widget.open()
    },
    async postData(){

      let img = this.CakeImg
      let title = this.titleCake
      let price = this.priceCake
      let tags = []
      tags.push(this.tagsCake)

      let allergens = []
      allergens.push(this.allergensCake)

      let paragrafh = this.paragrafh
      // var id
      // await axios.get(`/.netlify/functions/GetNewObjectID`).then((res) => {
      //   id = res.data.id
      // })
      await axios.post('/.netlify/functions/PostDataCollectionCakes',{img, title ,paragrafh, price, tags, allergens}).then(response => {
          console.log("work post");
      }).catch(error => {
          console.log(error);
      });
    }
    
  },
  created() {
  },
  mounted(){
    this.getda()
  
  }
 
  
  
}
</script>

<style scoped>

*{
  direction: rtl;
}


img{
  width: 300px;
  height: 300px;
}

input,label,textarea,button,select{
  position: relative;
  right: 30%;
  margin-top: 20px;
  font-size: 20px;
}

input,select{
  right: 32%;
}
</style>




