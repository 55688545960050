<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  
  },
  
}
</script>

<style>


@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

body{
  background-color: #fdf4da !important;
}

#app {
  /* margin-top: 100px; */
  /* font-family: system-ui; */
  font-family: 'Varela Round', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50 !important;
  background-color: #fdf4da !important;

  /* direction: rtl !important; */
}


/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: whitesmoke;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  border-left: 1px solid white;
  border-right: 1px solid white;
  background: #702c29; 
  border-radius: 10px;
  transition: 0.4s ease-in;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5c2522;
}

body{
  margin: 0;
  padding: 0;
}
</style>
