<template>
    <div class="column show">
      <div class="content">
        <!-- <img :src="require(`@/assets/photo/${CakeImgMenu}`)" -->
        <img :src="CakeImgMenu" alt="cake">
        <div class="content-title">
          <h4>{{ this.title }}</h4>
          <hr>
          <!-- <p>
          {{ this.paragrafh }}            
          </p> -->
          <h5><span>{{ this.price }}</span><span>&#8362;</span></h5>
          <!-- <h6>תגים: <span class="tagsName" v-for="tag in this.tags" :key="tag"><a href="search">{{ tag }}</a></span></h6> -->
          <span class="disable-id-cake">{{this.idCake}}</span>
          <div class="AddButtonDiv"><button @click="addToCart(id)" class="addCartProduct btn">מידע נוסף <i class="bi bi-info-circle"></i></button></div>
        </div>      
      </div>
    </div>  
</template>

<script>

import axios from 'axios'


export default {

  name: 'CakeContainer',
  props:["CakeImgMenu","title","paragrafh","price","tags","idCake","id"],
  mounted() {
      axios.get('/.netlify/functions/GetDataCollectionCakes').then(response => {
          this.products = response.data
      }).catch(error => {
          console.log(error);
      });
  },
  data(){
    return{
      products:[]
    }
  },
  methods: {
     addToCart(id) {
      var newData = {id:id,inCart:1,extras:[]}
      this.$emit('localAddProduct',newData)
  },
   cartNumbers(product){
 
    let productNumber = localStorage.getItem('cartNumbers');

    productNumber = parseInt(productNumber);

    if( productNumber ){
        localStorage.setItem('cartNumbers',productNumber + 1);
    } else {
        localStorage.setItem('cartNumbers',1);
    }

    this.setItems(product);
  },
   setItems(product) {
    let cartItems = localStorage.getItem('productsInCart');
    cartItems = JSON.parse(cartItems)

    if (cartItems != null) {
            
        if(cartItems[product.titleCake] == undefined){
            cartItems = {
                ...cartItems,
                [product.titleCake]: product
            }
        }
        cartItems[product.titleCake].inCart += 1; 
    } else {
        product.inCart = 1;
        cartItems = {
            [product.titleCake]: product
        }
    }
    localStorage.setItem("productsInCart", JSON.stringify(cartItems));
  }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

* {
  box-sizing: border-box;
  direction: rtl;
}

.disable-id-cake{
  display: none;
}

img{
  border-radius: 1rem 1rem 0 0;
  width: 100%;
  height: 300px;
  /* object-fit: cover; */
  object-fit: cover;
}

/* Center website */

h1 {
  font-size: 50px;
  word-break: break-all;
}

/* Create three equal columns that floats next to each other */
.column {
  border-radius: 1rem;
  float: right;
  margin-left: 2%;
  /* width: 310px; */
  display: none; /* Hide columns by default */
  margin-top: 3%;
  transition: 0.2s ease-out;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
  color: #4b2d3d;
}


/* .column:hover{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transform: translateY(-2%);
  transition: 0.2s ease-in;
} */

/* Content */
.content {
  border: 1px solid none;
  /* border-radius: 25px 25px 25px 25px; */
  width: 100% !important;
  text-align: center;
}

.content-title{
  border: 1px solid none;
  padding: 4% 4% 3% 4% ;
  border-radius: 0px 0px 25px 25px;
  color: #4b2d3d;
}

.content-title hr{
  width: 40%;
  margin-right: auto;
  margin-left: auto;
  padding: 5px;
  color: rgb(241, 0, 181);
}

.content-title p{
  border: 1px solid none;
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  color: black;
}

/* The "show" class is added to the filtered elements */
.show {
  display: block;
}


#myBtnContainer{
  margin-top: 10%;
}



h4,h5,h6{
  direction: rtl;
  text-align: center !important;
  color: #4b2d3d;
  padding: 10px;
}

h5{
  color: rgb(87, 176, 87);
}

.tagsName:hover{
  color: rgb(166, 0, 255);
}

.AddButtonDiv{
  width: auto;
  height: auto;
  padding: 15px;
}

.AddButtonDiv button{
  border:2px solid #f7e7d7;
  padding: 15px 10px;
  outline:none;
  border-radius: 25px;
  color: #4b2d3d;
}

.AddButtonDiv button:hover{
  background: #f7e7d7;
  
}


@media (max-width: 830px) {
  
  .column {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;  
  }

  h4{
    font-size: 13px;
  }
  h5{
    font-size: 14px;
  }
  h6{
    font-size: 12px;
  }

}


</style>
