<template>
<NavBar ref="NavbarCart"/>

<div class="AllContainer container">

    <section class="ImageDiv">
        <img :src="this.Product.img" alt="">
    </section>
    <section class="InfoDiv"> 
        <h1>{{ this.Product.title }}</h1>
        <h3>{{ this.Product.price }}₪</h3>
        <p>{{ this.Product.paragrafh }}</p>
        <br>
        <h6>תגים : <span v-for="(tag,index) in this.Product.tags" :key="tag">{{ tag }}<span v-if="index < this.Product.tags.length - 1">, </span></span></h6>
        <h6>אלרגנים : <span v-for="(allergen,index) in this.Product.allergens" :key="allergen">{{ allergen }}<span v-if="index < this.Product.allergens.length - 1">, </span></span></h6>
        <br>

        <div class="OrderInfo">
            <div>
                <label for="">תוספות: </label>
                <div v-for="(select, index) in selects" :key="index" class="select-group">
                    <label :for="'תוספת' + index">בחר תוספת {{ index + 1 }}:</label>
                    <div style="display: flex;flex-direction: row;">
                        <select :id="'תוספת' + index" v-model="select.value">
                            <option v-for="extra in this.Extras" :key="extra" :value="extra" >{{extra}}</option>
                        </select>
                        <button class="btn btn-danger" @click="removeSelect(index)"><i class="bi bi-trash"></i></button>
                    </div>
                    
                </div>
            </div>
            <br>
            <button style="width: 150px;" class="btn btn-secondary" @click="addSelect">עוד תוספות?</button>
            <br>
            <div>
                <label for="">תאריך: </label>
                <input type="date">
            </div>
            <br>
        </div>
        
        <button class="btn btn-primary" @click="this.addToCart(this.Product._id)">הוספה לסל <i class="bi bi-cart"></i></button>
        <!-- <i class="bi bi-dash-circle" @click="this.plusAndMinusButtonCart(this.Product,'minus')" @mouseenter="addClass" @mouseleave="removeClass"></i>
        <span v-if="this.SessionData">{{ this.Session.inCart }}</span>
        <span v-else>0</span>
        <i class="bi bi-plus-circle"  @click="this.plusAndMinusButtonCart(this.Product,'plus')" @mouseenter="addClass" @mouseleave="removeClass"></i> -->
    </section>
</div>


<Footer/>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

import axios from 'axios'

export default{
    name:"CakePage",
    props:[],
    components:{
        NavBar,
        Footer
    },
    data(){
        return{
            Product:[],
            SessionData:null,
            Extras:['שוקולד','אגוזי לוז','מקרון','קינדר בואנו'],
            selects: [
                { value: '' } // תיבת select ראשונית עם ערך ריק
            ]
        }
    },
    mounted(){
        console.log(this.$route.params.CakeTitle)
        const data = this.$route.params.CakeData
        console.log(data)
        if(data == undefined || data == null){
            this.getData()
        }else{
            this.Product = JSON.parse(data)
            console.log(this.Product)
        }    
    },
    methods:{
        addToCart(id) {
            var newData = {_id:id,inCart:1,extras:[]}

            for (let i = 0; i < this.selects.length; i++) {
                newData.extras.push(this.selects[i].value)
            }

            const SessionData = JSON.parse(sessionStorage.getItem('CartItems'));
            const newItem = newData // Assume 'item' is defined somewhere
            // console.log(newItem)

            let test = SessionData || [];

            const sameIndexItems = test.filter(existingItem => existingItem.id == newItem.id);
            console.log(sameIndexItems)
            console.log(newItem)

            let itemExists = false;
            if(test){
                for (const existingItem of sameIndexItems) {
                if (newItem.extras.length === existingItem.extras.length && newItem.extras.every(item => existingItem.extras.includes(item))) {
                    // If the extras are identical, update Item
                    existingItem.inCart++;
                    itemExists = true;
                    console.log("have")
                    

                } else {
                    // If extras are not identical, add new item
                    itemExists = false;
                    console.log(newItem.extras)
                    console.log(existingItem.extras)
                    // Exit the loop as we found a match
                }
            }

            if (!itemExists) {
            // If no matching extras were found, add the new item
            test.push(newItem);
            }
        }
        
        sessionStorage.setItem('CartItems', JSON.stringify(test));
        // this.SessionData()
        this.$refs.NavbarCart.SetData()
        // this.scrollToElement('cart')
        
        },            
        addSelect() {
          // הוספת תיבת select חדשה עם ערך ריק
          this.selects.push({ value: '' });
        },
        removeSelect(index) {
          // מחיקת תיבת select לפי אינדקס
          this.selects.splice(index, 1);
        },
        Console(){
            console.log(this.selects)
        },
        async getData(){
            
            await axios.get('/.netlify/functions/GetDataCollectionByTitle', {
                params: {
                    title: this.$route.params.CakeTitle,
                }   
            }).then(response => {
                this.Product = response.data
                console.log(this.Product)
            }).catch(error => {
                console.log(error);
            }); 

        },
        addClass: function (e) {
            if (e.target.classList.contains("bi-dash-circle")) {
                e.target.classList.replace("bi-dash-circle","bi-dash-circle-fill");
            }else if (e.target.classList.contains("bi-plus-circle")) {
                e.target.classList.replace("bi-plus-circle","bi-plus-circle-fill");
            }
        },
        removeClass: function (e) {
            if (e.target.classList.contains("bi-dash-circle-fill")) {
                e.target.classList.replace("bi-dash-circle-fill","bi-dash-circle");
            }else if (e.target.classList.contains("bi-plus-circle-fill")) {
                e.target.classList.replace("bi-plus-circle-fill","bi-plus-circle");
            }
        },
        async plusAndMinusButtonCart(product,type){
            const SessionData = JSON.parse(sessionStorage.getItem('CartItems'));
            if(SessionData){
                console.log("have session")
                let item = SessionData.find((item) => (item._id == product._id && item.extras.length === product.extras.length && item.extras.every(item => product.extras.includes(item))))
                console.log(item)
                if(item){
                    console.log(item)
                    let index = SessionData.findIndex((item) => item._id == product._id)
                    var ItemDeleted = false
                    if(type == 'plus'){
                        item.inCart ++
                        ItemDeleted = false
                    }else if(type == 'minus'){
                        if(item.inCart == 1){
                        await this.DeleteItem(product)
                        ItemDeleted = true
                        }else{
                        item.inCart --
                        ItemDeleted = false
                        }
                    }
                    if(!ItemDeleted){
                        sessionStorage.setItem('CartItems', JSON.stringify(SessionData));
                        this.getSession();
                    }
                }else if(type == 'plus'){
                    var newData = {id:product._id,inCart:1,extras:[]}
                    SessionData.push(newData)
                    sessionStorage.setItem('CartItems', JSON.stringify(SessionData));
                    
                }else if(type == "minus"){
                    console.error("cannot dicrese inCart")
                }
                
            }else if(type == 'plus'){
                var list = []
                var newData = {id:product._id,inCart:1,extras:[]}
                list.push(newData)
                sessionStorage.setItem('CartItems', JSON.stringify(list));
                
            }else if(type == "minus"){
                console.error("cannot dicrese inCart")
            }
            
        },
    }
}

</script>
<style scoped>

.AllContainer{
    border: 1px solid none;
    direction: ltr;
    display: grid;
    /* height: 70svh; */
    grid-template-columns: repeat(2,minmax(0,1fr));
    padding:50px 0px 50px 0px;
}

.ImageDiv{
    
    width: 80%;
    height: 99%;
    object-fit: cover;
}


.ImageDiv img{
    width: 100%;
    height: 100% !important;
    object-fit: cover;
}

.InfoDiv{
    width: 100%;
    text-align: right;
    direction: rtl;
    padding: 20px;
}

.OrderInfo{
    border: 1px solid none;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: right;
}

.OrderInfo div{
    display: flex;
    flex-direction: column;
}

@media (max-width: 850px) {
    .AllContainer{
        border: 1px solid none;
        direction: ltr;
        display: flex;
        flex-direction: column;
        
    }

    .ImageDiv{
    
        width: 100%;
        height: 99%;
        object-fit: cover;
    }

}




</style>